'use strict'

import CookiesEuBanner from 'cookies-eu-banner'
import ScrollReveal from 'scrollreveal'
import MicroModal from 'micromodal'
import lazySizes from 'lazysizes'
import $ from 'jquery'
require('./vendors/smooth-scroll.min.js')

function bookingModal () {
  MicroModal.init({
    awaitCloseAnimation: true,
    disableScroll: true
  })
}

function cookieBanner () {
  let cookieBanner = new CookiesEuBanner(function () { }, true)
}

function scroll () {
  let scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    easing: 'easeInOutCubic',
    updateURL: false
  })
}

function reveal () {
  ScrollReveal().reveal('.reveal', {
    duration: 1000,
    delay: 200,
    easing: 'cubic-bezier(0.23, 1, 0.32, 1)',
    distance: '50px'
  })

  ScrollReveal().reveal('.reveal-sequence', {
    duration: 1000,
    easing: 'cubic-bezier(0.23, 1, 0.32, 1)',
    interval: 100,
  })

  ScrollReveal().reveal('.reveal-content-sequence', {
    duration: 1000,
    easing: 'cubic-bezier(0.23, 1, 0.32, 1)',
    interval: 200,
    distance: '50px',
    mobile: false
  })
}

document.addEventListener('DOMContentLoaded', function (event) {
  bookingModal()
  scroll()
  cookieBanner()
  reveal()

  // Paralax Scrolling for Header Element on Index
  $(window).bind('scroll', function () {
    parallaxScroll()
  })

  function parallaxScroll () {
    let scrolledY = $(window).scrollTop()
    $('.header__image').css('background-position', 'center ' + (100 + (scrolledY * 0.3)) + '%')
    $('.header__image-inner').css('-webkit-transform', 'translateY(' + (scrolledY * -0.75) + 'px)')
    $('.header__links').css('-webkit-transform', 'translateY(' + (scrolledY * -0.75) + 'px)')
    $('.header__image-inner').css({ 'opacity': (100 - ($(window).scrollTop() / 4)) / 100 })
    $('.header__links').css({ 'opacity': (100 - ($(window).scrollTop() / 4)) / 100 })
  }
})
